import * as React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getActionName } from "../../_utils/commonFunctions";

const HeaderTitle = ({ action, title, type, onBackClick, subText, setOpen, id, secondaryTitle }, ...props) => {
  const navigate = useNavigate();
  return (
    <div className="headerTitleRow">
      <h1>{getActionName(action)} {title} {subText ? <small className="fs-body"> - {subText}</small> : ''} </h1>
      {
        id &&
        <Button
          type="button"
          variant="outlined"
          sx={{marginLeft: "auto" }}
          size="large"
          onClick={() => {
            setOpen(true)
          }}
        >
          {secondaryTitle}
        </Button>
      }
      <Button type="button" sx={{marginLeft: "5px" }} onClick={() => onBackClick ? onBackClick() : navigate("../", { state: { type } })} variant="default">
        <span className="mr-1 material-icons">chevron_left</span> Back to
        Listing
      </Button>
    </div>
  );
};

export default HeaderTitle;
