import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";
import { capitalize } from "lodash";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "merchandising";
// --------------------- Method Starts Here ---------------------

export const getBlockMetadata = createAsyncThunk(
  `${sliceName}/getBlockMetadata`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/block/getMetadata`);
  }
);

export const getBlockTableData = createAsyncThunk(
  `${sliceName}/getBlockTableData`,
  async (params) => {
    return await fetchWrapper.get(`${baseUrl}/block/getAll?${params || ""}`);
  }
);

export const getBlockVersionMetadata = createAsyncThunk(
  `${sliceName}/getBlockVersionMetadata`,
  async () => {
    return await fetchWrapper.get(
      `${baseUrl}/block/getMetadata?versioning=true`
    );
  }
);

export const getBlockVersionTableData = createAsyncThunk(
  `${sliceName}/getBlockVersionTableData`,
  async ({ params, id }) => {
    return await fetchWrapper.get(
      `${baseUrl}/block/version-history/get/${id}?${params || ""}`
    );
  }
);

// Dropdown list for navigation for block
export const getBlockList = createAsyncThunk(
  `${sliceName}/getBlockList`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/block/getAll`);
  }
);

export const addBlock = createAsyncThunk(
  `${sliceName}/addBlock`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/block/add`, values);
  }
);

export const getBlockById = createAsyncThunk(
  `${sliceName}/getBlockById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/block/${id}/get${params ? `?${params || ""}` : ''}`
    )
  }
);

export const updateBlock = createAsyncThunk(
  `${sliceName}/updateBlock`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/block/update/${values.id}`,
      values
    );
  }
);

export const duplicateBlock = createAsyncThunk(
  `${sliceName}/duplicateBlock`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/block/duplicate/${id}`);
  }
);

export const deleteBlock = createAsyncThunk(
  `${sliceName}/deleteBlock`,
  async ({ id, deletePermanent }) => {
    let res;
    if (deletePermanent) {
      res = await fetchWrapper.post(
        `${baseUrl}/block/delete-permanent/${id}`,
        { id }
      );
    } else {
      res = await fetchWrapper.post(`${baseUrl}/block/${id}/delete`, {
        id,
      });
    }
    return res || res === "" ? { id } : null;
  }
);

// Start Template components

export const addTemplate = createAsyncThunk(
  `${sliceName}/addTemplate`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/block-templates/add`, values);
  }
);

export const getTemplateMetadata = createAsyncThunk(
  `${sliceName}/getTemplateMetadata`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/block-templates/getMetadata`);
  }
);

export const getTemplateVersionMetadata = createAsyncThunk(
  `${sliceName}/getTemplateVersionMetadata`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/block-templates/getMetadata?versioning=true`);
  }
);

export const duplicateTemplate = createAsyncThunk(
  `${sliceName}/duplicateTemplate`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/block-templates/duplicate/${id}`);
  }
);

export const deleteTemplate = createAsyncThunk(
  `${sliceName}/deleteTemplate`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/block-templates/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getTemplateById = createAsyncThunk(
  `${sliceName}/getTemplateById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/block-templates/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const updateTemplate = createAsyncThunk(
  `${sliceName}/updateTemplate`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/block-templates/update/${values.id}`,
      values
    );
  }
);

export const getTemplateTableData = createAsyncThunk(
  `${sliceName}/getTemplateTableData`,
  async (params) => {
    return await fetchWrapper.get(`${baseUrl}/block-templates/getAll?${params || ""}`);
  }
);

export const getTemplateVersionTableData = createAsyncThunk(
  `${sliceName}/getTemplateVersionTableData`,
  async ({ params, id }) => {
    return await fetchWrapper.get(
      `${baseUrl}/block-templates/version-history/get/${id}?${params || ""}`
    );
  }
);

export const getAllTemplateData = createAsyncThunk(
  `${sliceName}/getAllTemplateData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/block-templates/getAll`);
  }
);

// Lists

export const getListMetadata = createAsyncThunk(
  `${sliceName}/getListMetadata`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/lists/getMetadata`);
  }
);

export const getAllList = createAsyncThunk(
  `${sliceName}/getAllList`,
  async ({ params }) => {
    return await fetchWrapper.get(`${baseUrl}/lists/getAll?${params || ""}`);
  }
);
// Start Site Components

export const addSiteComponent = createAsyncThunk(
  `${sliceName}/addSiteComponent`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/site-component/component-add`, values);
  }
);

export const getSiteComponentMetadata = createAsyncThunk(
  `${sliceName}/getSiteComponentMetadata`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/site-component/getMetadata`);
  }
);

export const getSiteComponentList = createAsyncThunk(
  `${sliceName}/getSiteComponentList`,
  async ({ params, selectedStoreId }) => {
    if (params) {
      return await fetchWrapper.get(`${baseUrl}/site-component/getAll?storeId=${selectedStoreId}&${params}`); 
    } else {
      return await fetchWrapper.get(`${baseUrl}/site-component/getAll?storeId=${selectedStoreId}`);
    }
  }
);

export const getSiteComponentDeletedList = createAsyncThunk(
  `${sliceName}/getSiteComponentList`,
  async ({ params, storeId }) => {
    if (params) {
      return await fetchWrapper.get(`${baseUrl}/site-component/getAll?storeId=${storeId}&isDeleted=true&${params}`);
    } else {
        return await fetchWrapper.get(`${baseUrl}/site-component/getAll?storeId=${storeId}&isDeleted=true`);
    }
  }
);

export const getSiteComponentVersionHistory = createAsyncThunk(
  `${sliceName}/getSiteComponentVersionHistory`,
  async ({ params, id }) => {
    if (params) {
      return await fetchWrapper.get(`${baseUrl}/site-component/version-history/get/${id}?${params || ""}`);
    } else {
      return await fetchWrapper.get(`${baseUrl}/site-component/version-history/get/${id}`);
    }
  }
);

export const duplicateSiteComponent = createAsyncThunk(
  `${sliceName}/duplicateSiteComponent`,
  async ({id, targetId}) => {
    return await fetchWrapper.post(`${baseUrl}/site-component/duplicate/${id}?storeId=${targetId}`);
  }
);

export const getSiteComponentById = createAsyncThunk(
  `${sliceName}/getSiteComponentById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/site-component/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const updateSiteComponent = createAsyncThunk(
  `${sliceName}/updateSiteComponent`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/site-component/${values.id}/update`,
      values
    );
  }
);

export const deletePermanentSiteComponent = createAsyncThunk(
  `${sliceName}/deletePermanentSiteComponent`,
  async ({ id }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/site-component/delete-permanent/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
// Ende Site Components
export const getSectionMetadata = createAsyncThunk(
  `${sliceName}/getSectionMetadata`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/section/getMetadata`);
  }
);

export const getSectionTableData = createAsyncThunk(
  `${sliceName}/getSectionTableData`,
  async (params) => {
    if (params) {
      return await fetchWrapper.get(`${baseUrl}/section/getAll?${params || ""}`);
    } else {
    return await fetchWrapper.get(`${baseUrl}/section/getAll`);
    }
  }
);

export const getSectionVersionMetadata = createAsyncThunk(
  `${sliceName}/getSectionVersionMetadata`,
  async () => {
    return await fetchWrapper.get(
      `${baseUrl}/section/getMetadata?versioning=true`
    );
  }
);

export const deleteSection = createAsyncThunk(
  `${sliceName}/deleteSection`,
  async ({ id, deletePermanent }) => {
    let res;
    if (deletePermanent) {
      res = await fetchWrapper.post(
        `${baseUrl}/section/delete-permanent/${id}`,
        { id }
      );
    } else {
      res = await fetchWrapper.post(`${baseUrl}/section/${id}/delete`, {
        id,
      });
    }
    return res || res === "" ? { id } : null;
  }
);


export const getSectionVersionTableData = createAsyncThunk(
  `${sliceName}/getSectionVersionTableData`,
  async ({ params, id }) => {
    return await fetchWrapper.get(
      `${baseUrl}/section/version-history/get/${id}?${params || ""}`
    );
  }
);

export const addSection = createAsyncThunk(
  `${sliceName}/addSection`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/section/add`, values);
  }
);

export const updateSection = createAsyncThunk(
  `${sliceName}/updateSection`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/section/${values.id}/update`,
      values
    );
  }
);

export const duplicateSection = createAsyncThunk(
  `${sliceName}/duplicateSection`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/section/duplicate/${id}`);
  }
);

export const getSectionById = createAsyncThunk(
  `${sliceName}/getSectionById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/section/${id}/get${params ? `?${params || ""}` : ''}`
    )
  }
);

// Lists
export const addList = createAsyncThunk(
  `${sliceName}/addList`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/lists/add`,
      values
    );
  }
);
// Dropdown list for block
export const getSectionList = createAsyncThunk(
  `${sliceName}/getSectionList`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/section/getAll`);
  }
);

export const getAllStoreList = createAsyncThunk(
  `${sliceName}/getAllStoreList`,
  async (params) => {
    return await fetchWrapper.get(`${baseUrl}/store/dropdown/getAll?${params || ""}`);
  }
);

export const getListById = createAsyncThunk(
  `${sliceName}/getListById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/lists/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const updateList = createAsyncThunk(
  `${sliceName}/updateList`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/lists/updateLists/${values.id}`,
      values
    );
  }
);

export const deleteList = createAsyncThunk(
  `${sliceName}/deleteList`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/lists/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const duplicateList = createAsyncThunk(
  `${sliceName}/duplicateList`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/site-component/duplicate/${id}`);
  }
);

export const getListVersionHistory = createAsyncThunk(
  `${sliceName}/getListVersionHistory`,
  async ({ params, id }) => {
    return await fetchWrapper.get(`${baseUrl}/lists/version-history/get/${id}?${params || ""}`);
  }
);

export const getNavigationTableDataByStoreId = createAsyncThunk(
  `${sliceName}/getNavigationTableDataByStoreId`,
  async (params) => {
    return await fetchWrapper.get(
      `${baseUrl}/navigation-hierarchy/getRoutings?${params || ""}`
    );
  }
);

export const getNavigationCollectinByStoreId = createAsyncThunk(
  `${sliceName}/getNavigationCollectinByStoreId`,
  async (params) => {
    return await fetchWrapper.get(
      `${baseUrl}/collection/getCollectionListByNavigationFilter?${params || ""}`
    );
  }
);

export const getNavigationByStoreId = createAsyncThunk(
  `${sliceName}/getNavigationByStoreId`,
  async (params) => {
    return await fetchWrapper.get(
      `${baseUrl}/navigation-hierarchy/getNavigationByStore?${params || ""}`
    );
  }
);

export const getNavigationVersionHistory = createAsyncThunk(
  `${sliceName}/getNavigationVersionHistory`,
  async (params) => {
    return await fetchWrapper.get(
      `${baseUrl}/navigation-hierarchy/version-history/get/${params || ""}`
    );
  }
);

export const getNavigationDeleteById = createAsyncThunk(
  `${sliceName}/getNavigationDeleteById`,
  async ({ id }) =>
    await fetchWrapper.post(`${baseUrl}/navigation-hierarchy/delete/${id}`, {
      id,
    })
);

export const getNavigationMetadataByStoreId = createAsyncThunk(
  `${sliceName}/getNavigationMetadataByStoreId`,
  async (params) => {
    return await fetchWrapper.get(
      `${baseUrl}/navigation-hierarchy/getRoutingMetadata?${params || ""}`
    );
  }
);

export const addNavigationHierarchy = createAsyncThunk(
  `${sliceName}/addNavigationHierarchy`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/navigation-hierarchy/add`,
      values
    );
  }
);

export const addNavigationHierarchyRootNavigationId = createAsyncThunk(
  `${sliceName}/addNavigationHierarchyRootNavigationId`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/navigation-hierarchy/navigation-add`,
      values
    );
  }
);

export const getNavigationHierarchyById = createAsyncThunk(
  `${sliceName}/getNavigationHierarchyById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/navigation-hierarchy/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getSiteComponentHeader = createAsyncThunk(
  `${sliceName}/getSiteComponentHeader`,
  async (id) => {
    return await fetchWrapper.get(`${baseUrl}/site-component/getComponentByType?storeId=${id}&type=header`);
  }
);

export const getSiteComponentFooter = createAsyncThunk(
  `${sliceName}/getSiteComponentFooter`,
  async (id) => {
    return await fetchWrapper.get(`${baseUrl}/site-component/getComponentByType?storeId=${id}&type=footer`);
  }
);

export const getAllCollectionTypes = createAsyncThunk(
  `${sliceName}/getAllCollectionTypes`,
  async (id) => {
    return await fetchWrapper.get(`${baseUrl}/collection/collection-type/getAllCollectionTypes`);
  }
);

export const duplicateNavigationHierarchy = createAsyncThunk(
  `${sliceName}/duplicateNavigationHierarchy`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/navigation-hierarchy/duplicate-move`,
      values
    );
  }
);

export const getMediaMetaData = createAsyncThunk(
  `${sliceName}/getMediaMetaData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/media/getMetadata`);
  }
);

export const updateNavigationHierarchy = createAsyncThunk(
  `${sliceName}/updateNavigationHierarchy`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/navigation-hierarchy/navigation-update/${values.id}`,
      values
    );
  }
);

export const getCatalogList = createAsyncThunk(
  `${sliceName}/getCatalogList`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/catalogs/get`);
  }
);

export const getTagValues = createAsyncThunk(
  `${sliceName}/getTagValues`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/tag-value/get`);
  }
);

export const getCollectionList = createAsyncThunk(
  `${sliceName}/getCollectionList`,
  async (params) => {
    return await fetchWrapper.get(`${baseUrl}/collection/getAll?${params || ""}`);
  }
);

export const getAllCollections = createAsyncThunk(
  `${sliceName}/getAllCollections`,
  async (params) => {
    return await fetchWrapper.get(`${baseUrl}/collection/getAllCollections?${params || ""}`);
  }
);


export const getAllMediaList = createAsyncThunk(
  `${sliceName}/getAllMediaList`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/media/getAll`);
  }
);

export const deleteSiteComponent = createAsyncThunk(
  `${sliceName}/deleteSiteComponent`,
  async ({ id }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/site-component/${id}/delete`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

// --------------------- Injections Start ---------------------

export const getInjectionsMetaData = createAsyncThunk(
  `${sliceName}/getInjectionsMetaData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/content-injection/getMetadata`);
  }
);
export const getAllInjectionsData = createAsyncThunk(
  `${sliceName}/getAllInjectionsData`,
  async ({ selectedStoreId, params }) => {
    return await fetchWrapper.get(`${baseUrl}/content-injection/getAll?storeId=${selectedStoreId}&${params || ""}`);
  }
);
export const getAllInjectionPositionData = createAsyncThunk(
  `${sliceName}/getAllInjectionPositionData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/content-injection/getContentInjectionPosition`);
  }
);

export const getInjectionById = createAsyncThunk(
  `${sliceName}/getInjectionById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/content-injection/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const updateInjection = createAsyncThunk(
  `${sliceName}/updateInjection`,
  async (values) => {
    return await fetchWrapper.post(
      `${baseUrl}/content-injection/wrapper-content-injection/update/${values.id}`,
      values
    );
  }
);

export const addInjection = createAsyncThunk(
  `${sliceName}/addInjection`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/content-injection/wrapper-content-injection/add`, values);
  }
);

export const deleteInjection = createAsyncThunk(
  `${sliceName}/deleteInjection`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/content-injection/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
export const getInjectionVersionHistory = createAsyncThunk(
  `${sliceName}/getInjectionVersionHistory`,
  async ({ id, params }) => {
    return await fetchWrapper.get(
      `${baseUrl}/content-injection/version-history/get/${id}?${params || ""}`
    );
  }
);

export const duplicateInjection = createAsyncThunk(
  `${sliceName}/duplicateInjection`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/content-injection/duplicate/${id}`);
  }
);

// --------------------- Injections End ---------------------
// --------------------- Features Start ---------------------

export const getFeaturesMetaData = createAsyncThunk(
  `${sliceName}/getFeaturesMetaData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/feature/getMetadata`);
  }
);

export const addFeatures = createAsyncThunk(
  `${sliceName}/addFeatures`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/feature/wrapper-feature/add`, values);
  }
);

export const getAllFeaturesData = createAsyncThunk(
  `${sliceName}/getAllFeaturesData`,
  async (param) => {
    return await fetchWrapper.get(`${baseUrl}/feature/getAll?${param}`);
  }
);

export const duplicateFeatures = createAsyncThunk(
  `${sliceName}/duplicateFeatures`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/feature/duplicate/${id}`);
  }
);

export const deleteFeatures = createAsyncThunk(
  `${sliceName}/deleteFeatures`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/feature/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);
export const getFeaturesVersionHistory = createAsyncThunk(
  `${sliceName}/getFeaturesVersionHistory`,
  async ({ id }) => {
    return await fetchWrapper.get(
      `${baseUrl}/feature/version-history/get/${id}`
    );
  }
);

export const getFeaturesById = createAsyncThunk(
  `${sliceName}/getFeaturesById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/feature/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getAllFeatureDisplayTypeData = createAsyncThunk(
  `${sliceName}/getAllFeatureDisplayTypeData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/feature/getFeatureDisplayType`);
  }
);
// Start Features Option

export const addFeaturesOption = createAsyncThunk(
  `${sliceName}/addFeaturesOption`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/feature-option/wrapper-feature-option/add`, values);
  }
);

export const getFeaturesOptionMetaData = createAsyncThunk(
  `${sliceName}/getFeaturesOptionMetaData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/feature-option/getMetadata`);
  }
);

export const getAllFeaturesOptionData = createAsyncThunk(
  `${sliceName}/getAllFeaturesOptionData`,
  async (param) => {
    return await fetchWrapper.get(`${baseUrl}/feature-option/getAll?${param}`);
  }
);

export const duplicateFeaturesOption = createAsyncThunk(
  `${sliceName}/duplicateFeaturesOption`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/feature-option/duplicate/${id}`);
  }
);

export const deleteFeaturesOption = createAsyncThunk(
  `${sliceName}/deleteFeaturesOption`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/feature-option/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const deletePermanentFeaturesOption = createAsyncThunk(
  `${sliceName}/deletePermanentFeaturesOption`,
  async ({ id }) => {
    const res = await fetchWrapper.post(`${baseUrl}/feature-option/delete-permanent/${id}`, {
      id,
    });
    return res || res === "" ? { id } : null;
  }
);

export const getFeatureOptionTypeData = createAsyncThunk(
  `${sliceName}/getFeatureOptionType`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/feature-option/getFeatureOptionType`);
  }
);

export const getFeatureOptionMetadata = createAsyncThunk(
  `${sliceName}/getFeatureOptionMetadata`,
  async () => {
    return await fetchWrapper.get(
      `${baseUrl}/feature-option/getMetadata?versioning=true`
    );
  }
);

export const getFeatureOptionVersionHistory = createAsyncThunk(
  `${sliceName}/getFeatureOptionVersionHistory`,
  async ({ id }) => {
    return await fetchWrapper.get(
      `${baseUrl}/feature-option/version-history/get/${id}`
    );
  }
);

export const getFeatureOptionById = createAsyncThunk(
  `${sliceName}/getFeatureOptionById`,
  async (id) => {
    return await fetchWrapper.get(`${baseUrl}/feature-option/get/${id}`);
  }
);
// End Features Option

// Start Features Group

export const getFeaturesGroupMetaData = createAsyncThunk(
  `${sliceName}/getFeaturesGroupMetaData`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/feature-group/getMetadata`);
  }
);

export const addFeaturesGroup = createAsyncThunk(
  `${sliceName}/addFeaturesGroup`,
  async (values) => {
    return await fetchWrapper.post(`${baseUrl}/feature-group/wrapper-feature-group/add`, values);
  }
);

export const getAllFeaturesGroupData = createAsyncThunk(
  `${sliceName}/getAllFeaturesGroupData`,
  async (param) => {
    if (param) {
      return await fetchWrapper.get(`${baseUrl}/feature-group/getAllByCollectionTypes?${param}`);
    } else {
      return await fetchWrapper.get(`${baseUrl}/feature-group/getAllByCollectionTypes`);
    }
  }
);

export const duplicateFeaturesGroup = createAsyncThunk(
  `${sliceName}/duplicateFeaturesGroup`,
  async (id) => {
    return await fetchWrapper.post(`${baseUrl}/feature-group/duplicate/${id}`);
  }
);

export const deleteFeaturesGroup = createAsyncThunk(
  `${sliceName}/deleteFeaturesGroup`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/feature-group/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getFeaturesGroupVersionHistory = createAsyncThunk(
  `${sliceName}/getFeaturesGroupVersionHistory`,
  async ({ id }) => {
    return await fetchWrapper.get(
      `${baseUrl}/feature-group/version-history/get/${id}`
    );
  }
);

export const getFeaturesGroupById = createAsyncThunk(
  `${sliceName}/getFeaturesGroupById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/feature-group/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const getFeaturesGroupOption = createAsyncThunk(
  `${sliceName}/getFeaturesGroupOption`,
  async () => {
    return await fetchWrapper.get(`${baseUrl}/feature-group/get-options`);
  }
);

// End Features Group
// --------------------- Features End ---------------------

// --------------------- Method Section Ends ---------------------

// slice
export const merchandising = createSlice({
  name: sliceName,
  initialState: {
    navigationFormData: null,
    selectedStoreSliceId: "",
    selectedDomain: "",
    blockDropdownList: [],
    catalogList: [],
    collectionList: [],
    tagsList: [],
    mediaList: [],
    footerList: [],
    headerList: [],
    dynamicSlug: [],
    templateList: [],
    featureOptionType: [],
    featureDisplayTypeData: [],
    featuresGroupData: [],
    featuresGroupOptionData: [],
    injectionOptionData: []
  },
  reducers: {
    setBlockDropdownList: (state, action) => {
      state.blockDropdownList = action.payload;
    },
    setSelectedStore: (state, action) => {
      state.selectedStoreSliceId = action.payload
    },
    setSelectedDomain: (state, action) => {
      state.selectedDomain = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBlockList.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.blockDropdownList = payload?.data.map((i) => ({
            ...i,
            value: i.id,
            label: i.name || i.id,
          }));
        } else {
          state.blockDropdownList = [];
        }
      })
      .addCase(getBlockList.rejected, (state) => {
        state.blockDropdownList = [];
      });

    // Injection Option
    builder
      .addCase(getAllInjectionPositionData.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.injectionOptionData = payload?.data?.map((i) => ({
            id: i.key,
            label: capitalize(i?.value || i.key),
          }));
        } else {
          state.injectionOptionData = [];
        }
      })
      .addCase(getAllInjectionPositionData.rejected, (state) => {
        state.injectionOptionData = [];
      });
    // Feature Option Type
    builder
      .addCase(getFeatureOptionTypeData.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.featureOptionType = payload?.data?.map((i) => ({
            ...i,
            id: i.key,
            label: i?.value || i.key,
          }));
        } else {
          state.featureOptionType = [];
        }
      })
      .addCase(getFeatureOptionTypeData.rejected, (state) => {
        state.featureOptionType = [];
      });

    // Feature Group
    builder
      .addCase(getAllFeaturesGroupData.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.featuresGroupData = payload?.data?.map((i) => ({
            value: i.id,
            label: i?.name || i.key,
          }));
        } else {
          state.featuresGroupData = [];
        }
      })
      .addCase(getAllFeaturesGroupData.rejected, (state) => {
        state.featureOptionType = [];
      });

    // Feature Group
    builder
      .addCase(getFeaturesGroupOption.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.featureGroupFeatureMapType)) {
          state.featuresGroupOptionData = payload?.featureGroupFeatureMapType?.map((i) => ({
            value: i.value,
            label: i?.name || i.key,
          }));
        } else {
          state.featuresGroupOptionData = [];
        }
      })
      .addCase(getFeaturesGroupOption.rejected, (state) => {
        state.featuresGroupOptionData = [];
      });

    // Feature Option Type
    builder
      .addCase(getAllFeatureDisplayTypeData.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.featureDisplayTypeData = payload?.data?.map((i) => ({
            ...i,
            id: i.key,
            label: i?.value || i.key,
          }));
        } else {
          state.featureDisplayTypeData = [];
        }
      })
      .addCase(getAllFeatureDisplayTypeData.rejected, (state) => {
        state.featureDisplayTypeData = [];
      });
    // Catalog list
    builder
      .addCase(getCatalogList.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload)) {
          state.catalogList = payload.map((i) => ({
            ...i,
            value: i.id,
            label: i.Name ? i.Name : i.id,
          }));
        } else {
          state.catalogList = [];
        }
      })
      .addCase(getCatalogList.rejected, (state) => {
        state.catalogList = [];
      });

    // Tags list
    builder
      .addCase(getTagValues.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload)) {
          state.tagsList = payload.map((i) => ({
            ...i,
            value: i.id,
            label: i.tagValue ?? i.id,
          }));
        } else {
          state.tagsList = [];
        }
      })
      .addCase(getTagValues.rejected, (state) => {
        state.tagsList = [];
      });
    // Footer list
    builder
      .addCase(getSiteComponentFooter.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload)) {
          state.footerList = payload?.map((i) => {
            return ({
              ...i,
              value: i.id,
              label: i.name ? i.name : i.id,
            })
          });
        } else {
          state.footerList = [];
        }
      })
      .addCase(getSiteComponentFooter.rejected, (state) => {
        state.footerList = [];
      });

    // Header list
    builder
      .addCase(getSiteComponentHeader.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload)) {
          state.headerList = payload.map((i) => {
            if (i.type === 'header') {
              return ({
                ...i,
                value: i.id,
                label: i.name ? i.name : i.id,
              })
            }
            return i;
          });
        } else {
          state.headerList = [];
        }
      })
      .addCase(getSiteComponentHeader.rejected, (state) => {
        state.headerList = [];
      });

    // Template list
    builder
      .addCase(getAllTemplateData.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.templateList = payload?.data?.map((i) => {
            return ({
              ...i,
              value: i.id,
              label: i.name ? i.name : i.id,
            })
          });
        } else {
          state.templateList = [];
        }
      })
      .addCase(getAllTemplateData.rejected, (state) => {
        state.templateList = [];
      });
    builder
      .addCase(getAllCollections.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.collectionList = payload?.data.map((i) => ({
            ...i,
            value: i.id,
            label: i.name ? i.name : i.id,
          }));
        } else {
          state.collectionList = [];
        }
      })
      .addCase(getAllCollections.rejected, (state) => {
        state.collectionList = [];
      });

    // Media list
    builder
      .addCase(getAllMediaList.fulfilled, (state, action) => {
        const { payload } = action;
        if (Array.isArray(payload?.data)) {
          state.mediaList = payload?.data.map((i) => ({
            ...i,
            value: i.id,
            label: i.name || i.id,
            url: i?.url
          }));
        } else {
          state.mediaList = [];
        }
      })
      .addCase(getAllMediaList.rejected, (state) => {
        state.mediaList = [];
      });
  },
});

export const merchandisingActions = merchandising.actions;

export default merchandising.reducer;
