import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper, generateUpdateApiQueryParams } from "_utils";

// create slice
const baseUrl = process.env.REACT_APP_API_URL;
const sliceName = "metalData";

// --------------------- Metal Starts Here ---------------------

export const getMetalsMetaDataByMetalId = createAsyncThunk(
  `${sliceName}/getMetalsMetaData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/metal/getMetadata?${params || ""}`)
);

export const getMetalsRowData = createAsyncThunk(
  `${sliceName}/getMetalsRowData`,
  async (params) => await fetchWrapper.get(`${baseUrl}/metal/getAll?${params || ""}`)
);

export const getMetalsMetaDataByMetalIdForVersion = createAsyncThunk(
  `${sliceName}/getMetalsMetaDataForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/metal/getMetadata?versioning=true&${params || ""}`)
);

export const getMetalTypesMetaDataByMetalIdForVersion = createAsyncThunk(
  `${sliceName}/getMetalTypesMetaDataByMetalIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/metal/metal-type/getMetadata?versioning=true&${params || ""}`)
);

export const getMetalPropertiesMetaDataByMetalIdForVersion = createAsyncThunk(
  `${sliceName}/getMetalPropertiesMetaDataByMetalIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/metal/metal-property/getMetadata?versioning=true&${params || ""}`)
);

export const getMetalUnitsMetaDataByMetalIdForVersion = createAsyncThunk(
  `${sliceName}/getMetalUnitsMetaDataByMetalIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/metal/metal-unit/getMetadata?versioning=true&${params || ""}`)
);

export const getMetalsRowDataByMetalId = createAsyncThunk(
  `${sliceName}/getMetalsRowDataByMetalId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/metal/version-history/get/${id}?${params || ""}`)
);

export const getMetalTypesRowDataByMetalIdForVersion = createAsyncThunk(
  `${sliceName}/getMetalTypesRowDataByMetalIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/metal/metal-type/version-history/get/${id}?${params || ""}`)
);

export const getMetalPropertiesRowDataByMetalIdForVersion = createAsyncThunk(
  `${sliceName}/getMetalPropertiesRowDataByMetalIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/metal/metal-property/version-history/get/${id}?${params || ""}`)
);

export const getMetalUnitsRowDataByMetalId = createAsyncThunk(
  `${sliceName}/getMetalUnitsRowDataByMetalId`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/metal/metal-unit/version-history/get/${id}?${params || ""}`)
);

export const getFinishMetaDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getFinishMetaDataByIdForVersion`,
  async (params) => await fetchWrapper.get(`${baseUrl}/finish/getMetadata?versioning=true&${params || ""}`)
);

export const getFinishRowDataByIdForVersion = createAsyncThunk(
  `${sliceName}/getFinishRowDataByIdForVersion`,
  async ({ params, id }) => await fetchWrapper.get(`${baseUrl}/finish/version-history/get/${id}?${params || ""}`)
);

export const deleteMetal = createAsyncThunk(
  `${sliceName}/deleteMetal`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/metal/delete${deletePermanent ? '-permanent' : ''}/${id}`, {
      id,
    });
    return res;
  }
);

export const getMetalTypesRowData = createAsyncThunk(
  `${sliceName}/getMetalTypesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/metal/metal-type/getAll?${params || ""}`)
);

export const getMetalTypesList = createAsyncThunk(
  `${sliceName}/getMetalTypesList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/metal/metal-type/dropdown/getAll?${params || ""}`)
);

export const getMetalTagByMetalId = createAsyncThunk(
  `${sliceName}/getMetalTagByMetalId`,
  async (id) =>
    await fetchWrapper.get(`${baseUrl}/metal/metal-type/${id}/all-tags/get`)
);

export const getAllMetals = createAsyncThunk(
  `${sliceName}/getAllMetals`,
  async () => await fetchWrapper.get(`${baseUrl}/metal/dropdown/getAll`)
);

export const getMetalById = createAsyncThunk(
  `${sliceName}/getMetalById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/metal/get/${id}?filter[include][][relation]=metalPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addMetalWrapper = createAsyncThunk(
  `${sliceName}/addMetalWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/wrapper-metal/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

//getMetalPropertiesByMetalTypeId
export const getMetalPropertiesByMetalTypeId = createAsyncThunk(
  `${sliceName}/getMetalPropertiesByMetalTypeId`,
  async (id) => await fetchWrapper.get(`${baseUrl}/metal/metal-type/get/${id}`)
);


export const getMetalTypesMetaData = createAsyncThunk(
  `${sliceName}/getMetalTypesMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/metal/metal-type/getMetadata`)
);

export const deleteMetalType = createAsyncThunk(
  `${sliceName}/deleteMetalType`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-type/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getAllMetalProperties = createAsyncThunk(
  `${sliceName}/getAllMetalProperties`,
  async () => await fetchWrapper.get(`${baseUrl}/metal/metal-property/getAll`)
);

export const getMetalTypeByIdWrapper = createAsyncThunk(
  `${sliceName}/getMetalTypeById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/metal/metal-type/get/${id}?filter[include][][relation]=allowedPropertiesValueMap${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addMetalTypeWrapper = createAsyncThunk(
  `${sliceName}/addMetalTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-type/wrapper-metal-type/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editMetalTypeWrapper = createAsyncThunk(
  `${sliceName}/editMetalTypeWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-type/wrapper-metal-type/update/${values.stateId}`,
      values.formData
    );
    return res || res === "" ? values : null;
  }
);

export const getMetalUnitsMetaData = createAsyncThunk(
  `${sliceName}/getMetalUnitsMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/metal/metal-unit/getMetadata`)
);

export const getMetalUnitsRowData = createAsyncThunk(
  `${sliceName}/getMetalUnitsRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/metal/metal-unit/getAll?${params || ""}`)
);

export const deleteMetalUnit = createAsyncThunk(
  `${sliceName}/deleteMetalUnit`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-unit/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const addMetalUnit = createAsyncThunk(
  `${sliceName}/addMetalUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-unit/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editMetalUnit = createAsyncThunk(
  `${sliceName}/editMetalUnit`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-unit/update/${values.id}`,
      values.formData
    );
    return res || res === "" ? values : null;
  }
);

export const getMetalUnitById = createAsyncThunk(
  `${sliceName}/getMetalUnitById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/metal/metal-unit/get/${id}?filter=${params ? `&${params || ""}` : ''}`
    )
  }
);

export const getMetalPropertiesMetaData = createAsyncThunk(
  `${sliceName}/getMetalPropertiesMetaData`,
  async () =>
    await fetchWrapper.get(`${baseUrl}/metal/metal-property/getMetadata`)
);

export const getMetalPropertiesRowData = createAsyncThunk(
  `${sliceName}/getMetalPropertiesRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/metal/metal-property/getAll?${params || ""}`)
);

export const deleteMetalProperty = createAsyncThunk(
  `${sliceName}/deleteMetalProperty`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-property/delete${deletePermanent ? '-permanent' : ''}/${id}`,
      { id }
    );
    return res || res === "" ? { id } : null;
  }
);

export const getAllMetalUnits = createAsyncThunk(
  `${sliceName}/getAllMetalUnits`,
  async () => await fetchWrapper.get(`${baseUrl}/metal/metal-unit/getAll`)
);

export const getMetalPropertyById = createAsyncThunk(
  `${sliceName}/getMetalPropertyById`,
    async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(
      `${baseUrl}/metal/metal-property/get/${id}?filter[include][][relation]=metalPropertyValues${params ? `&${params || ""}` : ''}`
    )
  }
);

export const addMetalPropertyWrapper = createAsyncThunk(
  `${sliceName}/addMetalPropertyWrapper`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-property/wrapper-metal-properties/add`,
      values
    );
    return res || res === "" ? res : null;
  }
);

export const editMetalWrapperProperty = createAsyncThunk(
  `${sliceName}/editMetalProperty`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/metal-property/wrapper-metal-properties/update/${values.id}`,
      values.formData
    );
    return res || res === "" ? values : null;
  }
);

// Finish
export const getFinishMetaData = createAsyncThunk(
  `${sliceName}/getFinishMetaData`,
  async () => await fetchWrapper.get(`${baseUrl}/finish/getMetadata`)
);

export const getFinishRowData = createAsyncThunk(
  `${sliceName}/getFinishRowData`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/finish/getAll?${params || ""}`)
);

export const getFinishList = createAsyncThunk(
  `${sliceName}/getFinishList`,
  async (params) =>
    await fetchWrapper.get(`${baseUrl}/finish/dropdown/getAll?${params || ""}`)
);

export const deleteFinish = createAsyncThunk(
  `${sliceName}/deleteFinish`,
  async ({ id, deletePermanent }) => {
    const res = await fetchWrapper.post(`${baseUrl}/finish/delete${deletePermanent ? '-permanent' : ''}/${id}`, { id });
    return res || res === "" ? { id } : null;
  }
);

export const getFinishById = createAsyncThunk(
  `${sliceName}/getFinishById`,
  async (apiParams) => {
    let id = apiParams?.id
    let params = undefined
    if (!id) {
      id = apiParams
    } else {
      params = generateUpdateApiQueryParams(apiParams?.params)
    }
    return await fetchWrapper.get(`${baseUrl}/finish/get/${id}${params ? `?${params || ""}` : ''}`
    )
  }
);

export const addFinish = createAsyncThunk(
  `${sliceName}/addFinish`,
  async (values) => {
    const res = await fetchWrapper.post(`${baseUrl}/finish/add`, values);
    return res || res === "" ? res : null;
  }
);

// Duplicate Actions
export const duplicateMetal = createAsyncThunk(
  `${sliceName}/duplicateMetal`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/metal/duplicate/${id}`)
);

export const duplicateMetalUnit = createAsyncThunk(
  `${sliceName}/duplicateMetalUnit`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/metal/metal-unit/duplicate/${id}`)
);

export const duplicateMetalType = createAsyncThunk(
  `${sliceName}/duplicateMetalType`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/metal/metal-type/duplicate/${id}`)
);

export const duplicateMetalProperty = createAsyncThunk(
  `${sliceName}/duplicateMetalProperty`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/metal/metal-property/duplicate/${id}`)
);

export const duplicateFinish = createAsyncThunk(
  `${sliceName}/duplicateFinish`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/finish/duplicate/${id}`)
);

export const duplicateActivity = createAsyncThunk(
  `${sliceName}/duplicateActivity`,
  async ({ id }) => await fetchWrapper.post(`${baseUrl}/activity/duplicate/${id}`)
);

export const generateCodeByType = createAsyncThunk(
  `${sliceName}/generateCodeByType`,
  async (values) => await fetchWrapper.post(`${baseUrl}/generate/code/type`, values)
);

export const addMetalBulkUpdates = createAsyncThunk(
  `${sliceName}/addMetalBulkUpdates`,
  async (values) => {
    const res = await fetchWrapper.post(
      `${baseUrl}/metal/bulk-update`,
      values
    );
    return res || res === "" ? res : null;
  }
);

// --------------------- Metal Section Ends ---------------------

// slice
export const metalSlice = createSlice({
  name: sliceName,
  initialState: {},
});

export default metalSlice.reducer;
